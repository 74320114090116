import './business-signin.css';
import React, { useEffect, useState, useRef } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { firestore } from './index';
import { useAuth } from './authContext';
import { useHistory } from 'react-router-dom';

const SubscriptionPage = () => {
    const { currentUser } = useAuth();
    const history = useHistory();
    const [promoCode, setPromoCode] = useState('');
    const continueRef = useRef(null);

    useEffect(() => {
        const checkPaymentStatus = async () => {
            if (currentUser) {
                try {
                    const userDocRef = doc(firestore, 'businesses', currentUser.uid);
                    const docSnap = await getDoc(userDocRef);

                    if (docSnap.exists() && docSnap.data().stripePayment === true) {
                        history.push('/businesses');
                    }
                } catch (error) {
                    console.error('Error checking payment status:', error);
                }
            }
        };

        checkPaymentStatus();
    }, [currentUser, history]);

    const handlePromoCode = async () => {
        if (promoCode === 'SAFIRAFREETRIAL') {
            try {
                const userDocRef = doc(firestore, 'businesses', currentUser.uid);
                await updateDoc(userDocRef, { stripePayment: true });
                history.push('/businesses');
            } catch (error) {
                console.error('Error updating payment status:', error);
            }
        } else {
            // Trigger animation for incorrect promo code
            if (continueRef.current) {
                continueRef.current.classList.add('shake');
                setTimeout(() => {
                    continueRef.current.classList.remove('shake');
                }, 500);
            }
        }
    };

    return (
        <div className="signin-page-container-payment-confirmed">
            <div className="max-w-4xl w-full p-6 flex flex-col md:flex-row items-center justify-between">

                {/* Left Side - Welcome Message */}
                <div className="text-white max-w-md">
                    <h1 className="text-4xl font-bold mb-4">Welcome to the Safira family.</h1>
                    <p className="text-lg">
                        Continue to your dashboard by signing up for a limited time free trial offer.
                    </p>

                    {/* Promo Code Input */}
                    <div className="mt-5">
                        <input 
                            type="text" 
                            placeholder="Enter promo code" 
                            value={promoCode}
                            onChange={(e) => setPromoCode(e.target.value)}
                            className="w-full p-3 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-salmon"
                            style={{ marginTop: '20px', color: 'black' }}
                        />
                        <p 
                            ref={continueRef}
                            onClick={handlePromoCode}
                            className="mt-2 text-salmon font-semibold cursor-pointer hover:underline"
                        >
                            Continue with a Promo Code
                        </p>
                    </div>
                </div>

                {/* Right Side - Pricing Card */}
                <div className="bg-white rounded-lg shadow-lg p-8 mt-8 md:mt-0 md:ml-10 w-full md:max-w-sm">
                    <h2 className="text-2xl font-semibold mb-2">7 days free.</h2>
                    <p className="text-sm text-gray-500 mb-6">
                        Try out Safira Hiring and be able to cancel anytime
                    </p>
                    <div className="text-6xl font-bold mb-4" style={{ color: 'salmon' }}>
                        $50 <span className="text-xl font-normal text-gray-500">/ month</span>
                    </div>
                    <ul className="space-y-2 mb-8">
                        <li className="text-gray-700">• Unlimited AI interviews</li>
                        <li className="text-gray-700">• 24/7 customer support</li>
                        <li className="text-gray-700">
                            • Personalized feedback for each candidate
                        </li>
                    </ul>
                    <a href="https://buy.stripe.com/cN2eYS75Dd8X0w0fYZ" target="_blank" rel="noopener noreferrer">
                        <button 
                            style={{ backgroundColor: 'salmon' }} 
                            className="w-full text-white font-semibold py-3 rounded-md shadow-md transition duration-200"
                            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#ff7f7f'} // Darker salmon on hover
                            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'salmon'} // Reset to original color
                        >
                            Subscribe Now
                        </button>
                    </a>
                </div>

            </div>
        </div>
    );
};

export default SubscriptionPage;
