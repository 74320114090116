import React, { useState, useEffect } from 'react';
import './OutreachSection.css';
import Papa from 'papaparse';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

function OutreachSection({ jobTitles, onSubmit }) {
  const [selectedJob, setSelectedJob] = useState('');
  const [emailTemplate, setEmailTemplate] = useState('');
  const [candidates, setCandidates] = useState([{ name: '', email: '' }]);
  const [file, setFile] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [company, setCompany] = useState('');
  const [emailSentMessage, setEmailSentMessage] = useState('');

  useEffect(() => {
    const auth = getAuth();
    const db = getFirestore();

    onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);
        const userDoc = await getDoc(doc(db, 'businesses', user.uid));
        if (userDoc.exists()) {
          setCompany(userDoc.data().company);
        }
      }
    });

    setSelectedJob('');
    setEmailTemplate('Hi {Name},\n\nWelcome to your {Job Title} interview at {Company Name}. This is an experimental interview which will not be a large part of the recruitment process.\n\nTake your interview here: {Link}\n\nBest regards,\nFractal');
  }, []);

  const handleJobChange = (e) => setSelectedJob(e.target.value);
  const handlePaste = (index, field, event) => {
    event.preventDefault();
    const paste = event.clipboardData.getData('text');
    const lines = paste.split('\n').map(line => line.trim()).filter(line => line);
    const entries = lines.map(line => line.split('\t')); // Split columns by tab

    const newCandidates = [...candidates];
    entries.forEach((entry, i) => {
      const name = entry[0] || '';
      const email = entry[1] || '';
      if (newCandidates[index + i]) {
        if (entry.length === 2) { // If two columns are pasted
          newCandidates[index + i].name = name;
          newCandidates[index + i].email = email;
        } else {
          newCandidates[index + i][field] = entry[0];
        }
      } else {
        if (entry.length === 2) { // If two columns are pasted
          newCandidates.push({ name, email });
        } else {
          const newEntry = { name: '', email: '' };
          newEntry[field] = entry[0];
          newCandidates.push(newEntry);
        }
      }
    });

    setCandidates(newCandidates);
  };

  const handleUploadEmails = async (data, jobTitle) => {
    console.log('Uploaded emails and names:', data);
    console.log('Selected job title:', jobTitle);
  
    try {
      const response = await fetch('https://fractalemailbackend-0d77e43ca422.herokuapp.com/send-emails', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data, selectedJob: jobTitle, customEmailTemplate: emailTemplate, uid: currentUser.uid, company: company }),
      });
  
      if (response.ok) {
        setEmailSentMessage('Emails have been sent successfully!');
        setCandidates([]); // Clear the table of candidates if emails are sent successfully
      } else {
        throw new Error('Failed to send emails');
      }
    } catch (error) {
      console.error('Error sending emails:', error);
      setEmailSentMessage('Failed to send emails. Please try again.');
    }
  };

  const handleSubmit = () => {
    if (!selectedJob) {
      alert('You must Select a Position to send the emails');
      return;
    }
    const data = candidates.filter(entry => entry.name && entry.email);
    handleUploadEmails(data, selectedJob);
  };

  const handleAddCandidate = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          const csvData = results.data.map((row) => ({
            name: row.Name,
            email: row.Email,
          }));
          setCandidates([...candidates, ...csvData]);
        }
      });
    }
  };

  const handleCandidateChange = (index, field, value) => {
    const newCandidates = [...candidates];
    newCandidates[index][field] = value;
    setCandidates(newCandidates);
  };
  const addEmptyCandidate = () => {
    setCandidates([...candidates, { name: '', email: '' }]);
  };

  const deleteCandidate = (index) => {
    const newCandidates = candidates.filter((_, i) => i !== index);
    setCandidates(newCandidates);
  };

  return (
    <div className="outreach-section">
      <div className="upload-popup-content">
        <div className="select-container">
          <select id="jobSelect" value={selectedJob} onChange={handleJobChange}>
            <option value="">Select a position</option>
            {jobTitles.map((job, index) => (
              <option key={index} value={job}>{job}</option>
            ))}
          </select>
        </div>
        {emailSentMessage && <p className="email-sent-message">{emailSentMessage}</p>}
      </div>
      <div className="content-container">
        <div className="edit-template-container">
          <div className="edit-template-header">Edit Template</div>
          <textarea
            className="edit-template-textarea"
            value={emailTemplate}
            onChange={(e) => setEmailTemplate(e.target.value)} // Make textarea editable
            placeholder="Type your email template here..." // Add placeholder text
          />
        </div>
        <div className="add-candidates-container">
          <div className="add-candidates-header">Add Candidates</div>
          <div className="button-container">
            <input
              type="file"
              accept=".csv"
              className="file-input"
              id= "fileInput"
              onChange={handleAddCandidate}
            />
            <label htmlFor="fileInput" className="custom-file-label">Import CSV</label>

          </div>
          <div className="sub-headers">
            <span>Candidate Name</span>
            <span>Email</span>
          </div>
          <div className="candidates-list">
            {candidates.map((candidate, index) => (
              <div key={index} className="candidate-row">
                <input
                  type="text"
                  value={candidate.name}
                  onChange={(e) => handleCandidateChange(index, 'name', e.target.value)}
                  placeholder="Name" // Add placeholder text
                  onPaste={(e) => handlePaste(index, 'name', e)}

                />
                <input
                  type="text"
                  value={candidate.email}
                  onChange={(e) => handleCandidateChange(index, 'email', e.target.value)}
                  placeholder="Email" // Add placeholder text
                  onPaste={(e) => handlePaste(index, 'email', e)}

                />
                <button className="delete-button" onClick={() => deleteCandidate(index)}>🗑️</button>
              </div>
            ))}
          </div>
          <div className="add-candidate-row">
            <button className="add-candidate-button" onClick={addEmptyCandidate}>➕</button>
          </div>
        </div>
      </div>
      <button className="send-interview-button" onClick={handleSubmit}> Send Interview</button>
    </div>
  );
}

export default OutreachSection;