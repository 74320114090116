import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './jobtitle.css'; // Ensure the CSS file import

const JobTitle = () => {
  const [jobTitle, setJobTitle] = useState('');
  const [showNextButton, setShowNextButton] = useState(false);
  const [showInput, setShowInput] = useState(false); // State to control the visibility of the input field
  const [fadeOut, setFadeOut] = useState(false); // State to control the fade-out animation
  const history = useHistory();
  const location = useLocation();

  const handleJobTitleChange = (e) => {
    const title = e.target.value;
    setJobTitle(title);
    setShowNextButton(title.trim() !== '');
  };

  const handleNext = (currentPath) => {
    setFadeOut(true); // Start the fade-out animation for the elements
    setTimeout(() => {
      let getReadyPath = '/uploadresume';

      if (currentPath.includes('/jobtitle/coding')) {
        getReadyPath = '/uploadresume/coding';
      }

      history.push({
        pathname: getReadyPath,
        state: { jobTitle }
      });
    }, 1000); // Duration of the fade-out animation
  };

  const headingText = "What position would you like to interview for?";
  const fadeInDuration = 0.02; // Duration for each character to fade in (in seconds)
  const totalDuration = headingText.length * fadeInDuration * 1000; // Total duration in milliseconds

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowInput(true);
    }, totalDuration + 500); // Add a small buffer to ensure all characters have faded in

    return () => clearTimeout(timer);
  }, [totalDuration]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter' && jobTitle.trim() !== '') { // Check if jobTitle is not empty
        handleNext(location.pathname);
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [location.pathname, jobTitle]);
  return (
    <div className="upload-resume-container">
      <h1 className={`job-title-title ${fadeOut ? 'fade-out' : ''}`}>
        {headingText.split("").map((char, index) => (
          <span key={index} className="fade-in-char" style={{ animationDelay: `${index * fadeInDuration}s` }}>
            {char === " " ? "\u00A0" : char}
          </span>
        ))}
      </h1>
      {showInput && (
        <div className={`input-container ${fadeOut ? 'fade-out' : ''}`}>
          <input
            type="text"
            value={jobTitle}
            onChange={handleJobTitleChange}
            placeholder="Enter job title"
            className="upload-resume-input"
          />
          {showNextButton && (
            <button
              type="button"
              className="jobtitle-next-button"
              onClick={() => handleNext(location.pathname)}
            >
              Next 
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default JobTitle;