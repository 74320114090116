import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Editor from '@monaco-editor/react';
import { Terminal } from 'xterm';
import { FitAddon } from 'xterm-addon-fit';
import { useAuth } from './authContext';
import { getFirestore } from 'firebase/firestore';
import 'xterm/css/xterm.css';
import './interviewcoding.css';

const languageComments = {
    python: '# Hello World!',
    cpp: '// Hello World!',
    java: '// Hello World!',
    swift: '// Hello World!',
};

const languageMonacoMapping = {
    python: 'python',
    cpp: 'cpp',
    java: 'java',
    swift: 'swift',
};

const ExampleBubbles = ({ messages, onSkip }) => {
    const containerRef = useRef(null);

    useEffect(() => {
        const container = containerRef.current;
        container.scrollTop = container.scrollHeight;
    }, [messages]);

    return (
        <div className="example-container" ref={containerRef}>
            <h1>AI Coding Interview</h1>
            {messages.map((msg, index) => (
                <div key={index} className={`bubble ${msg.user ? 'user' : ''}`}>
                    {msg.text}
                    {!msg.user && (
                        <button className="skip-button" onClick={onSkip}>
                            Skip &#x23ED;
                        </button>
                    )}
                </div>
            ))}
        </div>
    );
};






function MyEditor() {
    const { currentUser } = useAuth();
    const terminalRef = useRef(null);
    const terminalInstance = useRef(null);
    const [code, setCode] = useState(languageComments.python);
    const [language, setLanguage] = useState('python');
    const languageRef = useRef(language);
    const [messages, setMessages] = useState([]);
    const [chatMessages, setChatMessages] = useState([]);
    //const [aiSpeaking, setAiSpeaking] = useState(false);
    const aiSpeaking = useRef(false);
    const location = useLocation();
    const jobTitle = location.state ? location.state.jobTitle : '';
    const [interviewCompleted, setInterviewCompleted] = useState(false);
    const recognitionRef = useRef(null);
    const [isRecognizing, setIsRecognizing] = useState(false);
    const [terminalContent, setTerminalContent] = useState('');
    const history = useHistory();
    const audioRef = useRef(null);
    const db = getFirestore();
    const uid = currentUser.uid;
    const transcriptRef = useRef([]);
    const codeRef = useRef(code);
    const technicalQuestionRef = useRef('');

    const firstPrompt = `Hello, I am your coding interviewer today. Please explain your thought process and engage in conversation as you code. Are you ready for the technical question?`;

    const [showModal, setShowModal] = useState(false); // State to control the modal visibility
    
    const promptNewLine = () => {
        terminalInstance.current.write('\r\n\x1b[1;32m$ \x1b[0m');
    };
    
    useEffect(() => {
        transcriptRef.current.push(firstPrompt);
        setMessages(prevMessages => [...prevMessages, firstPrompt]);
        setChatMessages(prevMessages => [...prevMessages, { text: firstPrompt, user: false }]);
        speak(firstPrompt);
    }, []);

    useEffect(() => {
        const navbar = document.querySelector('.menu-bar');
        if (navbar) {
            navbar.classList.add('hide');
        }
        return () => {
            if (navbar) {
                navbar.classList.remove('hide');
            }
        };
    }, []);
    useEffect(() => {
        if (terminalRef.current) {
            const fitAddon = new FitAddon();
            terminalInstance.current = new Terminal({
                cursorBlink: true,
                rows: 10,
                cols: 80
            });
            terminalInstance.current.loadAddon(fitAddon);
            terminalInstance.current.open(terminalRef.current);
            fitAddon.fit();
    
            terminalInstance.current.writeln('Welcome to the Fractal Coding Interview!');
            promptNewLine();
    
            terminalInstance.current.onData(e => {
                switch (e) {
                    case '\r': // Enter key
                    case '\x0D': // Carriage return
                        terminalInstance.current.write('\r\n');
                        setTerminalContent(prev => prev + '\r\n');
                        promptNewLine();
                        break;
                    case '\x7f': // Backspace
                        terminalInstance.current.write('\b \b');
                        setTerminalContent(prev => prev.slice(0, -1));
                        break;
                    default:
                        terminalInstance.current.write(e);
                        setTerminalContent(e);
                        break;
                }
                terminalInstance.current.scrollToBottom();
            });
        }
    }, []);
    

    useEffect(() => {
        languageRef.current = language;
    }, [language]);

    const speak = (text) => {
        const formData = JSON.stringify({
            model: 'tts-1',
            voice: 'nova',
            input: text
        });

        console.log("AI talks");
        aiSpeaking.current = true;

        fetch('https://api.openai.com/v1/audio/speech', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer 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`,
                'Content-Type': 'application/json'
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.arrayBuffer();
        })
        .then(arrayBuffer => {
            const blob = new Blob([arrayBuffer], { type: 'audio/mpeg' });
            const blobURL = URL.createObjectURL(blob);
            const audio = new Audio(blobURL);
            audioRef.current = audio;
            audio.play();
            audio.onended = () => {
                console.log("AI done talking");
                aiSpeaking.current = false;
                startSpeechRecognition(true);
                audioRef.current = null;
            };
        })
        .catch(error => {
            console.error('Error fetching audio:', error);
            aiSpeaking.current = false;
            startSpeechRecognition(true);
        });
    };
    const startSpeechRecognition = (initialize = true) => {
        if (!interviewCompleted && !aiSpeaking.current) {
            if (initialize || !initialize) {
                console.log("Initializing speech recognition");
                recognitionRef.current = new window.webkitSpeechRecognition();
                const recognition = recognitionRef.current;
                recognition.lang = 'en-US';
                recognition.continuous = true;
                recognition.interimResults = true; // Capture interim results
                recognition.onresult = (event) => {
                    let interimTranscript = '';
                    let finalTranscript = '';
                    for (let i = 0; i < event.results.length; i++) {
                        const transcript = event.results[i][0].transcript;
                        if (event.results[i].isFinal) {
                            finalTranscript += transcript;
                        } else {
                            interimTranscript += transcript;
                        }
                    }
                    console.log('Interim Recognized:', interimTranscript);
                    console.log('Final Recognized:', finalTranscript);
    
                    setChatMessages(prevMessages => {
                        const updatedMessages = [...prevMessages];
                        if (updatedMessages.length > 0 && updatedMessages[updatedMessages.length - 1].user && !updatedMessages[updatedMessages.length - 1].isFinal) {
                            updatedMessages[updatedMessages.length - 1].text = interimTranscript || finalTranscript;
                            updatedMessages[updatedMessages.length - 1].isFinal = !!finalTranscript;
                        } else if (!finalTranscript) {
                            updatedMessages.push({ text: interimTranscript, user: true, isFinal: false });
                        }
                        return updatedMessages;
                    });
    
                    if (finalTranscript) {
                        transcriptRef.current.push('Candidate Message: ' + finalTranscript + '\n');
                        stopSpeechRecognition();
                        if (transcriptRef.current.length === 2) {
                            sendTechnicalQuestion();
                        } else {
                            sendMessageToServer();
                        }
                    }
                };
    
                recognition.onend = () => {
                    console.log("oops restarting speech recog");
                    if (!interviewCompleted) {
                        stopSpeechRecognition(); // Ensure we stop the current instance before restarting
                        setTimeout(() => {
                            startSpeechRecognition(false);
                        }, 500);  // Adding a small delay to prevent rapid consecutive restarts
                    }
                };
    
                recognition.onerror = (event) => {
                    console.error('Speech recognition error detected: ' + event.error);
                    if (!interviewCompleted) {
                        stopSpeechRecognition(); // Ensure we stop the current instance before restarting
                        setTimeout(() => {
                            startSpeechRecognition(false);
                        }, 500);  // Adding a small delay to prevent rapid consecutive restarts
                    }
                };
    
                recognition.start();
                console.log("Speech recognition started");
                setIsRecognizing(true);
            } else {
                // Restart the current instance without reinitializing
                console.log("Restarting speech recognition");
                recognitionRef.current.start();
            }
        }
    };
    
    


    const stopSpeechRecognition = () => {
        if (recognitionRef.current) {
            recognitionRef.current.onend = null; // Remove the onend handler to prevent it from triggering a restart
            recognitionRef.current.onerror = null;
            recognitionRef.current.stop();
            recognitionRef.current = null;
            setIsRecognizing(false);
            console.log("Speech recognition stopped");
        }
    };

    const sendTechnicalQuestion = async () => {
        console.log("Sending technical question");
        try {
            //https://fractalnodebackend-9e9cdf6a96a7.herokuapp.com
            //http://localhost:3001
            const response = await fetch(`https://fractalnodebackend-9e9cdf6a96a7.herokuapp.com/uid/${uid}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ message: '', ideContent: '', language: languageRef.current }), // Ensure language is included here
            });

            if (!response.ok) {
                throw new Error('Failed to send technical question');
            }

            const result = await response.json();
            const { aiResponse, functionSignature } = result;

            transcriptRef.current.push('Interviewer Message: ' + aiResponse + '\n');
            setMessages(prevMessages => [...prevMessages, aiResponse]);
            setChatMessages(prevMessages => [...prevMessages, { text: aiResponse, user: false }]);

            if (functionSignature) {
                setCode(languageComments[languageRef.current] + '\n\n' + functionSignature);
            }

            technicalQuestionRef.current = aiResponse;
            speak(aiResponse);
        } catch (error) {
            console.error('Error sending technical question:', error);
        }
    };

    const sendMessageToServer = async () => {
        const messageContent = transcriptRef.current.join('\n');
        const ideContent = `Current IDE Code: ${codeRef.current} \n`;

        console.log("Sending message to server");
        try {
            const response = await fetch(`https://fractalnodebackend-9e9cdf6a96a7.herokuapp.com/uid/${uid}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ message: messageContent, ideContent: ideContent }),
            });

            if (!response.ok) {
                throw new Error('Failed to send message');
            }

            const result = await response.json();
            const { aiResponse } = result;

            transcriptRef.current.push('Interviewer Message: ' + aiResponse + '\n');
            setMessages(prevMessages => [...prevMessages, aiResponse]);

            // Add AI response to chat messages
            setChatMessages(prevMessages => [
                ...prevMessages,
                { text: aiResponse, user: false } // Add the AI response
            ]);

            speak(aiResponse);
        } catch (error) {
            console.error('Error sending message to server:', error);
        }
    };

    const handleLanguageChange = async (newLanguage) => {
        // Update the language state and ref
        setLanguage(newLanguage);
        languageRef.current = newLanguage;
        console.log("new language is " + newLanguage);

        // If the technical question is not yet set, just update the language and return
        if (!technicalQuestionRef.current) {
            return;
        }

        try {
            const response = await fetch('https://fractalnodebackend-9e9cdf6a96a7.herokuapp.com/generate-signature', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ question: technicalQuestionRef.current, language: newLanguage }),
            });

            if (!response.ok) {
                throw new Error('Failed to generate function signature');
            }

            const { functionSignature } = await response.json();
            setCode(languageComments[newLanguage] + '\n\n' + functionSignature);
        } catch (error) {
            console.error('Error generating function signature:', error);
        }
    };

    const handleEnd = () => {
        setShowModal(true); // Show the modal instead of ending the interview
        console.log("end tapped")
    };

    const handleEndWithoutFeedback = () => {
        setShowModal(false);
        if (audioRef.current) {
            audioRef.current.pause();  // Pause the audio
            audioRef.current.src = ''; // Clear the source to ensure it stops completely
        }
        terminalInstance.current.writeln('Goodbye!');
        const navbar = document.querySelector('.menu-bar');
        if (navbar) {
            navbar.classList.remove('hide');
        }
        stopSpeechRecognition();

        releaseMicrophone();

        history.push('/coding-interview');
    };

    const handleEndWithFeedback = async () => {
        setShowModal(false);
        if (audioRef.current) {
            audioRef.current.pause();  // Pause the audio
            audioRef.current.src = ''; // Clear the source to ensure it stops completely
        }
        terminalInstance.current.writeln('Goodbye!');
        const navbar = document.querySelector('.menu-bar');
        if (navbar) {
            navbar.classList.remove('hide');
        }
        console.log("Updating server for feedback");
        stopSpeechRecognition();

    
        try {
            const response = await fetch(`https://fractalnodebackend-9e9cdf6a96a7.herokuapp.com/feedback/${uid}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    messageContent: transcriptRef.current.join('\n'),
                    ideContent: codeRef.current // Ensure the current code is sent
                }),
            });
            console.log("received data, before error check")
            if (!response.ok) {
                console.log("response not okay")
                throw new Error('Failed to send feedback request');
            }
    
            const result = await response.text();
            console.log('Received Data: ' + result);


            setInterviewCompleted(true);
            releaseMicrophone();

            history.push('/coding-interview');
        } catch (error) {
            console.error('Error sending feedback request:', error);
        }
    };

    const releaseMicrophone = () => {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ audio: true })
                .then(stream => {
                    stream.getTracks().forEach(track => track.stop());
                    console.log("Microphone access stopped");
                })
                .catch(err => console.error("Error stopping microphone access: ", err));
        }
    };

    const handleRunCode = async () => {
        if (!terminalInstance.current) {
            console.error('Terminal instance is not initialized.');
            return;
        }
    
        terminalInstance.current.writeln('Running code...');
        try {
            const response = await fetch('https://fractalnodebackend-9e9cdf6a96a7.herokuapp.com/run-code', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ code, language }),
            });
            if (!response.ok) {
                const errorDetails = await response.json();
                throw new Error(`Failed to execute code: ${errorDetails.details}`);
            }
            const result = await response.json();
            const output = result.output.replace(/\\n/g, '\n');
            const normalizedResult = output.replace(/\r\n|\r|\n/g, '\r\n');
            terminalInstance.current.writeln(normalizedResult);
            setTerminalContent(normalizedResult);
        } catch (error) {
            const normalizedResult = error.message.replace(/\r\n|\r|\n/g, '\r\n');
            terminalInstance.current.writeln(`Error: ${normalizedResult}`);
            setTerminalContent(`Error: ${normalizedResult}`);
        }
        terminalInstance.current.scrollToBottom();
        promptNewLine();
    };
    const handleSkip = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current = null;
        }
        aiSpeaking.current = false;
        startSpeechRecognition();
    };

    return (
        <div className="main-container">
            <div className="header-container">
                <h1 className="title">Practice Coding Interview</h1>
                <div className="button-container">
                    <button onClick={handleRunCode} className="run-button">Run Code</button>
                    <select
                        value={language}
                        onChange={(e) => {
                            handleLanguageChange(e.target.value);
                        }}
                        className="language-selector"
                    >
                        <option value="python">Python</option>
                        <option value="cpp">C++</option>
                        <option value="java">Java</option>
                        <option value="swift">Swift</option>
                    </select>
                    <button onClick={handleEnd} className="end-button">End Interview</button>
                </div>
            </div>
            <div className="content-container">
                <div className="ide-container">
                    <div className="editor-container">
                        <Editor
                            height="calc(85vh - 200px)"
                            language={languageMonacoMapping[language]}
                            value={code}
                            onChange={(value) => {
                                setCode(value || '');
                                codeRef.current = value || '';
                            }}
                        />
                    </div>
                    <div className="terminal-container" ref={terminalRef}></div>
                </div>
                <ExampleBubbles messages={chatMessages} onSkip={handleSkip} />
            </div>
    
            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <button className="close-button" onClick={() => setShowModal(false)}>x</button>
                        <h2>End Interview</h2>
                        <p>Would you like to end the interview with or without feedback?</p>
                        <button onClick={handleEndWithFeedback} className="feedback-button">
                            End Interview with Feedback
                            <p className="description">Your answer will be scored and you'll receive suggestions on improvement.</p>
                        </button>
                        <button onClick={handleEndWithoutFeedback} className="no-feedback-button">
                            End Interview without Feedback
                            <p className="description">You will be returned to the home page and all code will be lost.</p>
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
    
}

export default MyEditor;
