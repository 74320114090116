import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import './welcomeBusinessInterview.css';
import './App.css';

const WelcomeBusinessInterview = () => {
  const [fadeOut, setFadeOut] = useState(false); // State to control the fade-out animation
  const [welcomeText, setWelcomeText] = useState("Welcome");
  const history = useHistory();
  const location = useLocation();
  const pathParts = location.pathname.split('/');
  const whiteLabel = pathParts[pathParts.length - 1] === "ai-interview";
  const [firstPart, jobTitle, businessUID] = pathParts.slice(1, 4);

  const fadeInDuration = 0.2; // Duration for each character to fade in (in seconds)
  const totalDuration = welcomeText.length * fadeInDuration * 2000; // Total duration in milliseconds

  useEffect(() => {
    const fetchCompanyName = async () => {
      const db = getFirestore();
      const docRef = doc(db, 'businesses', businessUID);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const company = docSnap.data().company;
        const formattedJobTitle = jobTitle.replace(/-/g, ' ');
        setWelcomeText(`Welcome to your ${formattedJobTitle} interview at ${company}.`);
      } else {
        console.log("No such document!");
      }
    };

    fetchCompanyName();

    console.log("WelcomeInterview component mounted");

    if (whiteLabel) {
      const navbar = document.querySelector('.menu-bar');
      if (navbar) {
        navbar.classList.add('hide');
      }
    }

    const timer = setTimeout(() => {
      setFadeOut(true); // Start the fade-out animation for the text
      const navigateTimer = setTimeout(() => {
        const newPath = whiteLabel 
          ? `/name-and-email/${jobTitle}/${businessUID}/ai-interview` 
          : `/name-and-email/${jobTitle}/${businessUID}`;
        console.log(`Navigating to ${newPath}`);
        history.push(newPath);
      }, 1500);
      return () => clearTimeout(navigateTimer);
    }, totalDuration); // Duration for how long the welcome message stays

    return () => {
      clearTimeout(timer);
      console.log("WelcomeInterview component unmounted");
    };
  }, [history, jobTitle, businessUID, whiteLabel]);

  return (
    <div>
      <div className="welcome-container">
        <h1 className={`welcome-text ${fadeOut ? 'fade-out' : ''}`}>
          {welcomeText.split(" ").map((char, index) => (
            <span key={index} className="fade-in-char" style={{ animationDelay: `${index * fadeInDuration}s` }}>
              {char}&nbsp;
            </span>
          ))}
        </h1>
      </div>
    </div>
  );
};

export default WelcomeBusinessInterview;