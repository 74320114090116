import React, { useState } from 'react';
import { Redirect, Link, useHistory } from 'react-router-dom';
import { useAuth } from './authContext';
import { firestore } from './index';
import { getFirestore, setDoc, doc } from 'firebase/firestore/lite';
import { app } from './index';
import { doCreateUserWithEmailAndPassword } from './auth';
import './businessregister.css'; // Import the CSS file

const BusinessRegister = () => {
    const navigate = useHistory();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isRegistering, setIsRegistering] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const { userLoggedIn, setCurrentUser } = useAuth();
    const db = getFirestore(app);

    const onSubmit = async (e) => {
        e.preventDefault();
        if (!isRegistering) {
            if (password !== confirmPassword) {
                setErrorMessage("Passwords do not match");
                return;
            }

            setIsRegistering(true);
            try {
                const userCredential = await doCreateUserWithEmailAndPassword(email, password);
                const uid = userCredential.user.uid;
                console.log("User UID:", uid); // Log the UID

                // Add the user to the Firestore businesses collection
                await setDoc(doc(db, 'businesses', uid), {
                    email: email,
                    name: name,
                    company: company,
                    createdAt: new Date()
                }).then(() => {
                    console.log("Business document successfully written!");
                }).catch((error) => {
                    console.error("Error writing business document: ", error);
                });

                setCurrentUser(userCredential.user);
                navigate.push('/subscription-page');
            } catch (error) {
                console.error("Error during registration:", error); // Log any errors
                setIsRegistering(false);
                setErrorMessage(error.message);
            }
        }
    };

    return (
        <>
            {userLoggedIn && (<Redirect to={'/businesses'} replace={true} />)}

            <main className="register-page-container">
                <div className="register-main-container">
                    <div className="register-form-container">
                        <div className="text-center mb-6">
                            <div className="mt-2">
                                <h3 className="text-white text-xl font-semibold sm:text-2xl">Create an account.</h3>
                                <p className="text-white text-base">Stress-free hiring starts here.</p>
                            </div> 
                        </div>
                        <form onSubmit={onSubmit} className="register-form">
                            <div className="form-group">
                                <label className="text-sm text-white font-bold">
                                    Name
                                </label>
                                <input
                                    type="name"
                                    autoComplete='name'
                                    required
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    className="form-input"
                                />
                            </div>
                            <div className="form-group">
                                <label className="text-sm text-white font-bold">
                                    Company Name
                                </label>
                                <input
                                    type="company"
                                    autoComplete='company'
                                    required
                                    value={company}
                                    onChange={(e) => setCompany(e.target.value)}
                                    className="form-input"
                                />
                            </div>
                            <div className="form-group">
                                <label className="text-sm text-white font-bold">
                                    Business Email
                                </label>
                                <input
                                    type="email"
                                    autoComplete='email'
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="form-input"
                                />
                            </div>

                            <div className="form-group">
                                <label className="text-sm text-white font-bold">
                                    Password
                                </label>
                                <input
                                    disabled={isRegistering}
                                    type="password"
                                    autoComplete='new-password'
                                    required
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="form-input"
                                />
                            </div>

                            <div className="form-group">
                                <label className="text-sm text-white font-bold">
                                    Confirm Password
                                </label>
                                <input
                                    disabled={isRegistering}
                                    type="password"
                                    autoComplete='off'
                                    required
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    className="form-input"
                                />
                            </div>

                            {errorMessage && (
                                <span className='error-message'>{errorMessage}</span>
                            )}

                            <div className="flex justify-center items-center">
                                <button
                                    type="submit"
                                    disabled={isRegistering}
                                    className={`register-button ${isRegistering ? 'registering' : ''}`}
                                >
                                    {isRegistering ? 'Signing Up...' : 'Sign Up'}
                                </button>
                            </div>

                            <div className="text-sm text-center signup-text">
                                Already have an account? {' '}
                                <Link to={'/business-sign-in'} className="text-center text-sm hover:underline font-bold">Log in</Link>
                            </div>
                        </form>
                    </div>
                    {/* Image section */}
                    <div className="register-image-container">
                        <img src="/Login Art.png" alt="Register" className="register-image" />
                    </div>
                </div>
            </main>
        </>
    );
}

export default BusinessRegister;
