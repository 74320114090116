import React from 'react';
import { useHistory } from 'react-router-dom';
import './signIn.css';  // Ensure your CSS is correctly imported

const SignInSelectionPage = () => {
    const history = useHistory();
  
    const handleIndividualSignIn = () => {
      history.push('/sign-in');
    };
  
    const handleBusinessSignIn = () => {
      history.push('/business-sign-in');
    };
  
    return (
      <div className="sign-in-selection-container">
        <div className="sign-in-selection-content">
          <h2>Are you an individual or a business?</h2>
          <div className="sign-in-button-group">
            <button className="individual-sign-in-button" onClick={handleIndividualSignIn}>I'm an individual</button>
            <button className="business-sign-in-button" onClick={handleBusinessSignIn}>I'm a business</button>
          </div>
        </div>
      </div>
    );
  };
  
  export default SignInSelectionPage;
