import React, { useEffect, useState } from 'react';
import { getFirestore, collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { useAuth } from './authContext';
import { useHistory } from 'react-router-dom';
import './results_page.css';

const ResultsPage = () => {
    const { currentUser } = useAuth();
    const history = useHistory();
    const [results, setResults] = useState(null);
    const [selectedSection, setSelectedSection] = useState('overall');

    useEffect(() => {
        const fetchResults = async () => {
            const db = getFirestore();
            const uid = currentUser.uid;

            // Query to get the most recent interview
            const interviewsRef = collection(db, 'users', uid, 'interviews');
            const q = query(interviewsRef, orderBy('submission_time', 'desc'), limit(1));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const recentInterview = querySnapshot.docs[0].data();
                setResults(recentInterview);
            } else {
                console.log('No interviews found!');
            }
        };

        if (currentUser) {
            fetchResults();
        }
    }, [currentUser]);

    // Function to calculate the average score
    const calculateAverageScore = (scores) => {
        if (!scores) return 'No scores available';

        const scoreValues = Object.values(scores);
        const totalScore = scoreValues.reduce((sum, score) => sum + score, 0);
        const averageScore = (totalScore / scoreValues.length).toFixed(2); // Rounds to 2 decimal places

        return averageScore;
    };

    const renderFeedback = () => {
        if (!results) return <p>Loading feedback...</p>;

        if (selectedSection === 'overall') {
            const averageScore = calculateAverageScore(results.scores);
            return (
                <>
                    <h2>Overall Average Score</h2>
                    <div className="average-score-circle">
                        {averageScore}
                    </div>
                </>
            );
        }

        return (
            <>
                <h2>{selectedSection.charAt(0).toUpperCase() + selectedSection.slice(1)} Feedback</h2>
                <div className='feedback-inner-box'>
                    <p>{results.feedback[selectedSection]}</p>
                </div>
            </>
        );
    };

    const handleNextClick = () => {
        history.push('/practice');
    };

    return (
        <div className="results-container">
            <div className="results-section">
                <div className="results-title-box">
                    <h1>Results</h1>
                </div>
                <div className={`feedback-box ${selectedSection === 'overall' ? 'overall-selected' : ''}`}>
                    {renderFeedback()}
                </div>
            </div>
            <div className="score-container">
                <div className="button-group">
                    <button_results
                        className={`overall-btn ${selectedSection === 'overall' ? 'selected' : ''}`}
                        onClick={() => setSelectedSection('overall')}
                    >
                        <span className="text">◀ Overall:</span>
                        <span className="score">{results ? results.scores.overall : '...'}</span>
                    </button_results>
                    {results &&
                        Object.entries(results.scores)
                            .sort(([, scoreA], [, scoreB]) => scoreB - scoreA)
                            .map(([characteristic, score]) => (
                                <button_results
                                    key={characteristic}
                                    className={`score-item ${selectedSection === characteristic ? 'selected' : ''}`}
                                    onClick={() => setSelectedSection(characteristic)}
                                >
                                    <span className="text">◀ {characteristic.charAt(0).toUpperCase() + characteristic.slice(1)}:</span>
                                    <span className="score">{score}</span>
                                </button_results>
                            ))}
                    <button_results className="next-btn" onClick={handleNextClick}>
                        Next
                    </button_results>
                </div>
            </div>
        </div>
    );
};

export default ResultsPage;
