import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import './UploadEmailsPopup.css';

function UploadEmailsPopup({ isOpen, onClose, jobTitles, onSubmit, emailSentMessage }) {
  const [selectedJob, setSelectedJob] = useState('');
  const [file, setFile] = useState(null);
  const [individualEntries, setIndividualEntries] = useState([{ name: '', email: '' }]);

  useEffect(() => {
    if (isOpen) {
      setSelectedJob('');
      setFile(null);
      setIndividualEntries([{ name: '', email: '' }]);
    }
  }, [isOpen]);

  const handleJobChange = (e) => {
    setSelectedJob(e.target.value);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleIndividualChange = (index, field, value) => {
    const newEntries = individualEntries.map((entry, i) =>
      i === index ? { ...entry, [field]: value } : entry
    );
    setIndividualEntries(newEntries);
  };

  const handlePaste = (index, field, event) => {
    event.preventDefault();
    const paste = event.clipboardData.getData('text');
    const lines = paste.split('\n').map(line => line.trim()).filter(line => line);
    const entries = lines.map(line => line.split('\t')); // Split columns by tab

    const newEntries = [...individualEntries];
    entries.forEach((entry, i) => {
      const name = entry[0] || '';
      const email = entry[1] || '';
      if (newEntries[index + i]) {
        if (entries[0].length === 2) { // If two columns are pasted
          newEntries[index + i].name = name;
          newEntries[index + i].email = email;
        } else {
          newEntries[index + i][field] = entry[0];
        }
      } else {
        if (entries[0].length === 2) { // If two columns are pasted
          newEntries.push({ name, email });
        } else {
          const newEntry = { name: '', email: '' };
          newEntry[field] = entry[0];
          newEntries.push(newEntry);
        }
      }
    });

    setIndividualEntries(newEntries);
  };

  const handleAddEntry = () => {
    setIndividualEntries([...individualEntries, { name: '', email: '' }]);
  };

  const handleRemoveEntry = (index) => {
    setIndividualEntries(individualEntries.filter((_, i) => i !== index));
  };

  const handleSubmit = () => {
    if (!selectedJob) {
      alert('You must select a position to send the emails');
      return;
    }
    const data = individualEntries.filter(entry => entry.name && entry.email);
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          const csvData = results.data.map((row) => ({
            email: row.Email,
            name: row.Name,
          }));
          onSubmit([...data, ...csvData], selectedJob);
        }
      });
    } else {
      onSubmit(data, selectedJob);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="upload-emails-popup">
      <div className="upload-popup-content">
        <h2>Upload Emails</h2>
        <select value={selectedJob} onChange={handleJobChange}>
          <option value="">Select a position</option>
          {jobTitles.map((job, index) => (
            <option key={index} value={job}>{job}</option>
          ))}
        </select>

        {/* <input type="file" accept=".csv" onChange={handleFileChange} /> */}

        <div className="individual-entries">
          <h3>Add Individual Entries</h3>
          {individualEntries.map((entry, index) => (
            <div key={index} className="individual-entry">
              <input
                type="text"
                placeholder="Name"
                value={entry.name}
                onChange={(e) => handleIndividualChange(index, 'name', e.target.value)}
                onPaste={(e) => handlePaste(index, 'name', e)}
              />
              <input
                type="email"
                placeholder="Email"
                value={entry.email}
                onChange={(e) => handleIndividualChange(index, 'email', e.target.value)}
                onPaste={(e) => handlePaste(index, 'email', e)}
              />
              <button type="button" onClick={() => handleRemoveEntry(index)}>Remove</button>
            </div>
          ))}
          <div className="add-entry-button-container">
            <button type="button" className="add-entry-button" onClick={handleAddEntry}>
              <i className="fas fa-plus"></i>
            </button>
          </div>
        </div>

        <div className="button-container">
          <button onClick={onClose} className="close-upload-popup-button">Close</button>
          <button onClick={handleSubmit} className="submit-button">Submit</button>
        </div>
        {emailSentMessage && <p className="email-sent-message">{emailSentMessage}</p>}
      </div>
    </div>
  );
}

export default UploadEmailsPopup;
