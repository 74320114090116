import React, { useState } from 'react';
import { FaChevronRight, FaChevronDown, FaEnvelope, FaStar, FaBriefcase, FaQuestionCircle, FaChartLine, FaUsers, FaUser, FaCog } from 'react-icons/fa';
import './businessSidebar.css';

const BusinessSidebar = ({ handleButtonClick, currentUser, company, activeSection }) => {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleItemClick = (itemName) => {
    setSelectedItem(itemName);
    handleButtonClick(itemName);
    console.log(selectedItem)
    
  };

  return (
    <aside className="sidebar">
      <div className="sidebar-header">
        <h2>{selectedItem ? selectedItem : "Positions"}</h2>
      </div>
      <nav className="sidebar-nav">
        <table>
          <tbody>

            <tr
              className={activeSection === 'positions' ? 'selected' : ''}
              onClick={() => handleItemClick('Positions')}
            >
              <td><FaBriefcase /></td>
              <td>Positions</td>
              <td><FaChevronRight /></td>
            </tr>
            <tr
              className={activeSection === 'candidates' ? 'selected' : ''}
              onClick={() => handleItemClick('Candidates')}
            >
              <td><FaChartLine /></td>
              <td>Candidates</td>
              <td><FaChevronRight /></td>
            </tr>
            <tr
              className={activeSection === 'starred' ? 'selected' : ''}
              onClick={() => handleItemClick('Starred')}
            >
              <td><FaStar /></td>
              <td>Starred</td>
              <td><FaChevronRight /></td>
            </tr>
            {/* <tr
              className={activeSection === 'outreach' ? 'selected' : ''}
              onClick={() => handleItemClick('Outreach')}
            >
              <td><FaEnvelope /></td>
              <td>Outreach</td>
              <td><FaChevronRight /></td>
            </tr> */}

            {/* <tr
              className={activeSection === 'settings' ? 'selected' : ''}
              onClick={() => handleItemClick('Settings')}
            >
              <td><FaCog /></td>
              <td>Settings</td>
              <td><FaChevronRight /></td>
            </tr> */}
            <tr
              className={activeSection === 'help' ? 'selected' : ''}
              onClick={() => handleItemClick('Help')}
            >
              <td><FaQuestionCircle /></td>
              <td>Help</td>
              <td><FaChevronRight /></td>
            </tr>
          </tbody>
        </table>
      </nav>
      <div className="sidebar-footer">
        <div className="user-info">
          <FaUser size={40} />
          <div className="user-details">
            <p className="user-name">{currentUser?.displayName}</p>
            <p className="user-company">{company}</p>
          </div>
        </div>
        <div className="arrow-icon">
          <FaChevronDown />
        </div>
      </div>
    </aside>
  );
};

export default BusinessSidebar;