import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './authContext';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { BrowserRouter } from 'react-router-dom';
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import '@fortawesome/fontawesome-free/css/all.min.css';


const firebaseConfig = {
  apiKey: "AIzaSyCl3jvqAuKbk639atoxLP0KtI9EbVSHykc",
  authDomain: "fractal-74bae.firebaseapp.com",
  projectId: "fractal-74bae",
  storageBucket: "fractal-74bae.appspot.com",
  messagingSenderId: "142621993392",
  appId: "1:142621993392:web:7ab5abdee5df168b07ce75",
  measurementId: "G-DT0G0LQSP1"
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const analytics = getAnalytics(app);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <AuthProvider>
      <App />
    </AuthProvider>
  </BrowserRouter>
);

export { app, firestore, auth };

reportWebVitals();