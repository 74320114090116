import React, { useRef, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useAuth } from './authContext';
import { app } from './index';
import PracticePage from './practice_page';
import { getFirestore, doc, updateDoc, getDoc } from 'firebase/firestore';
import './interview.css';

let chat_log = [];
let topics_list = [];
let prev_newChat = "";
let prematureSpace = false;

const BusinessInterview = () => {
    const { currentUser } = useAuth();
    const [showAlert, setShowAlert] = useState(true);
    const location = useLocation();
    const videoRef = useRef(null);
    const jobTitle = location.state ? location.state.jobTitle : '';
    const aiAudioRef = useRef(null); // Add this at the beginning of your component
    const uid = currentUser.uid;
    const db = getFirestore(app);
    const [chatMessages, setChatMessages] = useState([]);
    const [aiSpeaking, setAiSpeaking] = useState(false);
    const [interviewCompleted, setInterviewCompleted] = useState(false);
    const recognitionRef = useRef(null); // Ref to hold the recognition object
    const history = useHistory();
    const questionsColumnRef = useRef(null);
    const [isRecognizing, setIsRecognizing] = useState(false);
    const [isBusiness, setIsBusiness] = useState(false);
    const [isValidInterview, setIsValidInterview] = useState(false);
    const extractedUidRef = useRef(null);
    const jobPathRef = useRef(null);
    const jobPathTitleRef = useRef(null);
    const uniqueIdRef = useRef(null);

    const checkInterviewValidity = async (uid, jobPath, uniqueId) => {
        const db = getFirestore();
        const jobPathWithSpaces = jobPath.replace(/-/g, ' ');
    
        const userRef = doc(db, 'businesses', uid);
        const userSnapshot = await getDoc(userRef);
    
        if (userSnapshot.exists()) {
          const jobRef = doc(db, 'businesses', uid, jobPathWithSpaces, 'interviewees');
          const jobSnapshot = await getDoc(jobRef);
    
          if (jobSnapshot.exists() && jobSnapshot.data()[uniqueId]) {
            console.log("interviewee exists");
            return true;
          }
        }
        console.log("interviewee does not exist");
        return false;
      };

    useEffect(() => {
        const validateInterview = async () => {
          const pathParts = location.pathname.split('/');
    
          if (pathParts.length === 5) {
            const [_, __, extractedUid, jobPath, uniqueId] = pathParts;
            extractedUidRef.current = extractedUid;
            jobPathRef.current = jobPath;
            jobPathTitleRef.current = jobPathRef.current.replace(/-/g, ' ');
            uniqueIdRef.current = uniqueId;
            setIsBusiness(true);
    
            if (extractedUid && jobPath && uniqueId) {
              const isValid = await checkInterviewValidity(extractedUid, jobPath, uniqueId);
              setIsValidInterview(isValid);
            } else {
              setIsValidInterview(false);
            }
          } else {
            setIsValidInterview(true);
          }
        };
    
        validateInterview();
      }, [location.pathname]);

    const scrollToBottom = () => {
        setTimeout(() => {
            if (questionsColumnRef.current) {
                questionsColumnRef.current.scrollTop = questionsColumnRef.current.scrollHeight;
            }
        }, 0);
    };      

    useEffect(() => {
        const startFlaskServer = async (uid) => {
            setInterviewCompleted(false);
            console.log("started");
            try {
                //https://fractalflaskapp-5c91849aadae.herokuapp.com/${uid}/${jobTitle}/interview
                const response = await fetch(`https://fractalflaskapp-5c91849aadae.herokuapp.com/interview/${extractedUidRef.current}/${jobPathRef.current}/${uniqueIdRef.current}`, {
                    method: 'GET',
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log('Received data:', data);
                    if (data.newChat && data.newChat !== prev_newChat) {
                        setChatMessages(prevMessages => [...prevMessages, data.newChat]);
                        prev_newChat = data.newChat;
                        chat_log = data.chat_log || [];
                        topics_list = data.topics_list || [];
                        speak(data.newChat);
                    }
                } else {
                    console.error('Interview failed');
                }
            } catch (error) {
                console.error('Error during interview:', error);
            }
        };

        startFlaskServer(uid);
    }, []);

    useEffect(() => {
        let streamRef = null; // Add a ref to store the stream
    
        navigator.mediaDevices.getUserMedia({ video: true, audio: true })
            .then((stream) => {
                videoRef.current.srcObject = stream;
                streamRef = stream; // Store the stream
            })
            .catch((error) => {
                console.error('Error accessing camera and microphone:', error);
            });
    
        // Cleanup function
        return () => {
            if (streamRef) {
                streamRef.getTracks().forEach(track => track.stop()); // Stop each track
                console.log("Camera and microphone are now turned off.");
            }
        };
    }, []);

    useEffect(() => {
        const navbar = document.querySelector('.menu-bar');
        if (navbar) {
            navbar.classList.add('hide');
        }
    }, []);

    useEffect(() => {
        // Show alert for 5 seconds
        const timer = setTimeout(() => setShowAlert(false), 5000);
        return () => clearTimeout(timer); // Cleanup timer
    }, []);

    const speak = (text) => {
        const formData = JSON.stringify({
            model: 'tts-1',
            voice: 'nova',
            input: text
        });
    
        console.log("AI talks");
        setAiSpeaking(true);
    
        fetch('https://api.openai.com/v1/audio/speech', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer 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`,
                'Content-Type': 'application/json'
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.arrayBuffer();
        })
        .then(arrayBuffer => {
            const blob = new Blob([arrayBuffer], { type: 'audio/mpeg' });
            const blobURL = URL.createObjectURL(blob);
            const audio = new Audio(blobURL);
            aiAudioRef.current = audio; // Save the audio object in the ref
            audio.play();
            audio.onended = () => {
                console.log("AI done talking");
                setAiSpeaking(false);
                startSpeechRecognition();
                aiAudioRef.current = null; // Clear the ref when audio ends
            };
        })
        .catch(error => {
            console.error('Error fetching audio:', error);
            setAiSpeaking(false);
        });
    };      
    
    const startSpeechRecognition = () => {
        if (!interviewCompleted) {
            setIsRecognizing(true);
            recognitionRef.current = new window.webkitSpeechRecognition();
            const recognition = recognitionRef.current;
            recognition.lang = 'en-US';
            recognition.continuous = true;
            recognition.interimResults = false;
            recognition.onresult = (event) => {
                if(prematureSpace){
                    prematureSpace = false;
                    updateServer(event.results[event.results.length - 1][0].transcript);
                    stopSpeechRecognition();
                }
                const transcript = event.results[event.results.length - 1][0].transcript;
                console.log('Recognized:', transcript);
                if (recognitionRef.current.latestTranscript === undefined){
                    recognitionRef.current.latestTranscript = transcript;
                } else{
                    recognitionRef.current.latestTranscript += transcript;
                }
            };
            recognition.start();
            console.log("Speech recognition started");

            // Add spacebar listener to stop and send transcript
            document.addEventListener('keydown', handleSpacebarPress);
        }
    };
    
    const handleEndInterview = () => {
        setInterviewCompleted(true);
        stopSpeechRecognition();
        if (aiAudioRef.current) {
            aiAudioRef.current.pause();
            aiAudioRef.current = null;
            setAiSpeaking(false);
        }
        chat_log = [];
        topics_list = [];
        prev_newChat = "";
        //newSpeak("Thank you for interviewing. Due to the interview ending prematurely, your scores will not be recorded.");
        console.log("finished");
        document.removeEventListener('keydown', handleSpacebarPress);
        history.push('/practice');
    }

    const stopSpeechRecognition = () => {
        if (recognitionRef.current) {
            setIsRecognizing(false);
            recognitionRef.current.stop();
            recognitionRef.current.latestTranscript = '';
            console.log("Speech recognition stopped");
            document.removeEventListener('keydown', handleSpacebarPress); // Clean up the event listener
        }
    };

    const handleSpacebarPress = (e) => {
        if (e.key === ' ' && !e.repeat) {
            e.preventDefault(); // Prevent the spacebar from scrolling the page
            if(recognitionRef.current.latestTranscript == undefined){
                prematureSpace = true;
            }
            if (recognitionRef.current.latestTranscript !== '' && recognitionRef.current.latestTranscript !== undefined) {
                console.log("Sending transcript to server");
                const transcript = recognitionRef.current.latestTranscript; // Handle obtaining the transcript correctly
                console.log("latest transcript: " + transcript);
                updateServer(transcript);
                stopSpeechRecognition(); // Stops recognition and removes listener
            }
        }
    };

    const newSpeak = (text) => {
        const formData = JSON.stringify({
            model: 'tts-1',
            voice: 'nova',
            input: text
        });
    
        console.log("AI talks");
        setAiSpeaking(true);
    
        fetch('https://api.openai.com/v1/audio/speech', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer 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`,
                'Content-Type': 'application/json'
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.arrayBuffer();
        })
        .then(arrayBuffer => {
            const blob = new Blob([arrayBuffer], { type: 'audio/mpeg' });
            const blobURL = URL.createObjectURL(blob);
            const audio = new Audio(blobURL);
            aiAudioRef.current = audio; // Save the audio object in the ref
            audio.play();
            audio.onended = () => {
                console.log("AI done talking");
                setAiSpeaking(false);
                aiAudioRef.current = null; // Clear the ref when audio ends
            };
        })
        .catch(error => {
            console.error('Error fetching audio:', error);
            setAiSpeaking(false);
        });
    };     

    const updateServer = async (transcript) => {
        try {
            // Check if the interview is ongoing
            if (!interviewCompleted) {
                const response = await fetch(`https://fractalflaskapp-5c91849aadae.herokuapp.com/interview/${extractedUidRef.current}/${jobPathRef.current}/${uniqueIdRef.current}`, {
                    method: 'POST',
                    headers: {
                        'X-React-App': 'true',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ response: transcript, chat_log: chat_log, topics_list: topics_list })
                });
                                
                if (response.ok) {
                    console.log('Response sent to server successfully.');
                    const data = await response.json(); // Parse JSON response
                    if (data.status === 'Interview completed') {
                        setInterviewCompleted(true);
                        stopSpeechRecognition();
                        newSpeak("Thank you for interviewing. You can review your scores here in the practice tab");
                        console.log("finished");
                        history.push('/practice');
                    } else if (!data.status) { // Assuming server doesn't return status for regular messages
                        if (data.newChat && data.newChat !== prev_newChat) {
                            setChatMessages(prevMessages => [...prevMessages, data.newChat]);
                            prev_newChat = data.newChat;
                            chat_log = data.chat_log || [];
                            topics_list = data.topics_list || [];
                            speak(data.newChat);
                            scrollToBottom();
                        }
                    }
                } else {
                    console.error('Failed to send response to server.');
                }
            } else {
                console.log("Interview already completed. Skipping server update.");
            }
        } catch (error) {
            console.error('Error sending response to server:', error);
        }
    };    

    return (
        <div className="interview-container">
             {showAlert && (
                <div className="alert">
                    <p>Click the space bar when done giving an answer</p>
                </div>
            )}
        <div className="questions-column" ref={questionsColumnRef}>
            <h2>Questions</h2>
            <div className="speech-bubbles">
                {chatMessages.map((message, index) => (
                    <div className='bubble' key={index}>
                        <h3>{message}</h3>
                    </div>
                ))}
            </div>
            <div className={`thinking-dots ${isRecognizing ? '' : 'recognizing'}`}></div>
        </div>
        <div className="int-video-container">
            <h1>{jobPathTitleRef.current } Interview</h1>
            <p>Wait for the Interviewer to stop speaking before you start. Click the spacebar when you're done giving an answer. Good luck!</p>
            <div className="video-wrapper">
                <video autoPlay playsInline muted ref={videoRef}></video>
                
            </div>
        </div>
        <button className="end-interview-button" onClick={handleEndInterview}> End Interview </button>
        
    </div>
    );    
}

export default BusinessInterview;
