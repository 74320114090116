import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './WelcomeInterview.css';

const WelcomeInterview = () => {
  const [fadeOut, setFadeOut] = useState(false); // State to control the fade-out animation
  const history = useHistory();

  const welcomeText = "Welcome";
  const fadeInDuration = 0.02; // Duration for each character to fade in (in seconds)
  const totalDuration = welcomeText.length * fadeInDuration * 1000; // Total duration in milliseconds

  useEffect(() => {
    console.log("WelcomeInterview component mounted");
    const timer = setTimeout(() => {
      setFadeOut(true); // Start the fade-out animation for the text
      const navigateTimer = setTimeout(() => {
        console.log("Navigating to /jobtitle");
        history.push('/jobtitle');
      }, 1000); // Duration of the fade-out animation
      return () => clearTimeout(navigateTimer);
    }, 1500); // Duration for how long the welcome message stays

    return () => {
      clearTimeout(timer);
      console.log("WelcomeInterview component unmounted");
    };
  }, [history]);

  return (
    <div className="welcome-container">
      <h1 className={`welcome-text ${fadeOut ? 'fade-out' : ''}`}>
        {welcomeText.split("").map((char, index) => (
          <span key={index} className="fade-in-char" style={{ animationDelay: `${index * fadeInDuration}s` }}>
            {char}
          </span>
        ))}
      </h1>
    </div>
  );
};

export default WelcomeInterview;
