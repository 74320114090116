import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link,useHistory,withRouter} from 'react-router-dom';
import { app } from './index';
import {getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useAuth } from './authContext';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'; // Import MUI components for pie chart
import './practice_page.css';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF', '#FF19AB'];

const formatDate = (timestamp) => {
  const date = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
  const time = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }); // Remove seconds
  return { formattedDate, time };
};

const formatFeedback = (feedback) => {
  const colors = {
    "logic": '#0088FE',
    "problem solving": '#00C49F',
    "dsa": '#FFBB28',
    "positivity": '#FF8042',
    "speed": '#AF19FF',
    "grit": '#FF19AB',
  };

  return feedback.split('\n').map((line, idx) => {
    const [key, ...valueParts] = line.split(': ');
    const value = valueParts.join(': '); // Handle cases where the description itself contains colons
    const formattedKey = key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();
    const cleanedValue = value?.replace(/(^"|"$)/g, '').replace(/,$/, '').replace(/"$/, ''); // Remove leading and trailing quotes and commas
    return (
      <React.Fragment key={idx}>
        <span style={{ fontWeight: 'bold', color: colors[key.toLowerCase()] }}>{formattedKey}</span>: {cleanedValue}
        {idx !== feedback.split('\n').length - 1 && <br />} {/* Add <br> except for the last line */}
      </React.Fragment>
    );
  });
};

const FeedbackTable = ({ data }) => {
  return (
    <div className="feedback-table rounded-container">
      <h2 className="table-title">Interview Feedback</h2>
      <TableContainer component={Paper}>
        <Table aria-label="feedback table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold", width: "15%", backgroundColor: "#f0f0f0" }}>Date</TableCell>
              <TableCell>Feedback</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => {
              const { formattedDate, time } = formatDate(item.submission_time);
              return (
                <TableRow key={index}>
                  <TableCell style={{ fontWeight: "bold", backgroundColor: "#f0f0f0" }}>
                    {formattedDate}
                    <br />
                    <span style={{ fontSize: "0.8em", color: "gray" }}>{time}</span>
                  </TableCell>
                  <TableCell>{formatFeedback(item.feedback)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const CodingPracticePage = () => {
  const { currentUser } = useAuth();
  const [lineChartData, setLineChartData] = useState([]);
  const [feedbackData, setFeedbackData] = useState([]);
  const [latestEntry, setLatestEntry] = useState({});
  const history = useHistory();

  const uid = currentUser.uid;

  useEffect(() => {
    const navbar = document.querySelector('.menu-bar');
    if (navbar) {
        navbar.classList.remove('hide');
    }
}, []);

  useEffect(() => {
    const fetchData = async () => {
      const db = getFirestore(app);
      const feedbackRef = collection(db, 'users', uid, 'codinginterviews');
      const feedbackSnapshot = await getDocs(feedbackRef);
      const feedbackData = [];
  
      feedbackSnapshot.forEach((doc) => {
        const { submission_time, feedback } = doc.data();
        const formattedDate = new Date(submission_time * 1000).toISOString().split('T')[0];
        const formattedFeedback = {};
  
        // Normalize the keys and remove non-alphanumeric characters
        Object.entries(feedback).forEach(([key, value]) => {
          const normalizedKey = key.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
          formattedFeedback[normalizedKey] = value;
        });
  
        const feedbackString = Object.entries(formattedFeedback)
          .map(([key, value]) => `${key}: ${value}`)
          .join('\n');
        feedbackData.push({ submission_time, date: formattedDate, feedback: feedbackString }); // Include formattedDate in the data
      });
  
      feedbackData.sort((a, b) => b.submission_time - a.submission_time); // Sort based on submission_time
  
      console.log("Fetched feedback data:", feedbackData);
  
      setFeedbackData(feedbackData);
    };
  
    fetchData();
  }, []);  

  useEffect(() => {
    const fetchData = async () => {
      const db = getFirestore(app);
      const userRef = collection(db, 'users', uid, 'codinginterviews');
      const interviewSnapshot = await getDocs(userRef);
      const data = [];
  
      interviewSnapshot.forEach((doc) => {
        const { submission_time, scores } = doc.data();
        const date = new Date(submission_time * 1000); // Convert Unix timestamp to milliseconds
        const formattedDate = date.toISOString().split('T')[0]; // Get the date part only
        const entry = { submission_time, date: formattedDate }; // Include submission_time in each entry
  
        Object.keys(scores).forEach((key) => {
          // Normalize the key to lowercase and remove non-alphanumeric characters
          const normalizedKey = key.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
          entry[normalizedKey] = scores[key];
        });
  
        data.push(entry);
      });
  
      console.log("Fetched and processed data:", data);
  
      const sortedData = data.sort((a, b) => a.submission_time - b.submission_time); // Sort based on submission_time
  
      console.log("Sorted data:", sortedData);

      setLineChartData(sortedData);
  
      if (sortedData.length > 0) {
        const latestData = sortedData[sortedData.length - 1]; // Get the latest entry
        setLatestEntry(latestData);
      } else {
        setLatestEntry({
          submission_time: 0,
          date: "",
          "logic": 0,
          "problem solving": 0,
          "dsa": 0,
          "positivity": 0,
          "speed": 0,
          "grit": 0
        });
      }
    };
  
    fetchData();
  }, []);   

  return (
    <div className="practice-page">
      <div className="wide-rectangle">
        <div className="practice-now-container">
          <h2 className="practice-title" style={{ marginBottom: "10px" }}>Practice Now!</h2>
          <button
            style={{
              backgroundColor: "lightcoral",
              border: "none",
              color: "white",
              padding: "15px 32px",
              textAlign: "center",
              textDecoration: "none",
              display: "inline-block",
              fontSize: "16px",
              margin: "4px 2px",
              cursor: "pointer",
              borderRadius: "5px",
              transition: "background-color 0.3s ease"
            }}
            onMouseEnter={(e) => e.target.style.backgroundColor = "#9e2a2b"}
            onMouseLeave={(e) => e.target.style.backgroundColor = "lightcoral"}
            onClick={() => history.push('/jobtitle/coding')}
          >
            Interview Me
          </button>
        </div>
        <div style={{ flex: "1", textAlign: "center", marginBottom: "20px" }}>
          <h3 style={{ marginTop: "0px" }}>Last Coding Interview Results</h3>
          <div className='data-container'>
            {Object.keys(latestEntry).map((key, index) => (
              key !== "date" && key !== "submission_time" &&
              <div className="circle-container" key={index} style={{ marginTop: "30px" }}>
                <div className="circle" style={{ backgroundColor: COLORS[index-2] }}>{latestEntry[key]}</div>
                <p className = "key-font">{key}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="rounded-container">
        <h2 className="graph-title">Score History</h2>
        <div className="line-chart-container" style={{ width: '100%', height: '500px' }}> {/* Set the container to 100% width */}
          <ResponsiveContainer>
            <LineChart data={lineChartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              {COLORS.map((color, index) => (
                <Line key={index} type="monotone" dataKey={Object.keys(latestEntry)[index+2]} stroke={color} />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      <FeedbackTable data={feedbackData} />

    </div>
  );
};

export default CodingPracticePage;
