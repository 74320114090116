import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './business-signin.css';
import { useAuth } from './authContext';
import { getFirestore, doc, setDoc } from 'firebase/firestore/lite';
import { app } from './index';

const PaymentConfirmed = () => {
    const history = useHistory();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const { currentUser } = useAuth();
    const [validAccess, setValidAccess] = useState(false);
    const firestore = getFirestore(app);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');

        if (token) {
            // Store the token in Local Storage
            localStorage.setItem('paymentToken', token);

            // Remove token from URL
            queryParams.delete('token');
            history.replace({ search: queryParams.toString() });

            setValidAccess(true);
        } else {
            // Check Local Storage for a valid token
            const storedToken = localStorage.getItem('paymentToken');
            if (storedToken === 'oL6X11~_y9v1') {
                setValidAccess(true);
            } else {
                history.push('/'); // Redirect if token is missing or invalid
            }
        }

        setLoading(false);
    }, [history, location]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!validAccess) {
        return null; // Render nothing if access is not valid
    }

    const handleContinue = async () => {
        try {
            // Clear the token after use
            localStorage.removeItem('paymentToken');

            if (currentUser && currentUser.uid) {
                const userRef = doc(firestore, 'businesses', currentUser.uid);

                // Create or update the stripePayment field
                await setDoc(userRef, { stripePayment: true }, { merge: true });
            }

            history.push('/businesses');
        } catch (error) {
            console.error('Error updating Firestore:', error);
            alert('Failed to update payment status. Please try again.');
        }
    };

    return (
        <div className="signin-page-container-payment-confirmed">
            <div className="content-container-payment-confirmed">
                <div className="congrats-message-payment-confirmed">
                    Congrats! Your payment has been confirmed.
                </div>
                <button 
                    className="continue-button-payment-confirmed" 
                    onClick={handleContinue}
                >
                    Continue to business dashboard
                </button>
            </div>
        </div>
    );
};

export default PaymentConfirmed;
