import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './PositionsSection.css';
import AddJobPopup from './AddJobPopup';
import { getDoc, getFirestore, doc } from 'firebase/firestore/lite';
import { app } from './index';
import { useAuth } from './authContext';

const PositionsSection = ({ jobTitles, handleButtonClick }) => {
  const [positions, setPositions] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false); // State to control the popup
  const [applicantsCount, setApplicantsCount] = useState({});
  const [editData, setEditData] = useState(null); // State to hold data for editing
  const { currentUser } = useAuth();
  const history = useHistory();

  const handleCreatePosition = () => {
    setEditData(null); // Clear edit data when creating a new position
    setIsPopupOpen(true); // Show the popup when the button is clicked
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false); // Hide the popup when it's closed
  };

  const handleAddJob = (jobTitle, jobDescription, customQuestions, personalityTraits) => {
    // Logic to add a new job (you can replace this with your actual logic)
    const newJob = { title: jobTitle, createdDate: new Date().toLocaleDateString(), applicants: 0, starred: 0 };
    setPositions([...positions, newJob]);
    setIsPopupOpen(false); // Close the popup after adding the job
  };

  const fetchCreatedAt = async (title) => {
    const db = getFirestore(app);
    const jobDocRef = doc(db, 'businesses', currentUser.uid, title, 'jobDescription');
    const jobDoc = await getDoc(jobDocRef);
    if (jobDoc.exists()) {
      const jobData = jobDoc.data();
      return jobData.createdAt || "N/A";
    }
    return 0;
  };

  const fetchApplicantsCount = async (title) => {
    const db = getFirestore(app);
    const jobDocRef = doc(db, 'businesses', currentUser.uid, title, 'interviewees');
    const jobDoc = await getDoc(jobDocRef);
    let applicantsCount = 0;
    let starredCount = 0;
    if (jobDoc.exists()) {
      const intervieweesData = jobDoc.data();
      applicantsCount = Object.keys(intervieweesData).length;
      Object.values(intervieweesData).forEach(interviewee => {
        if (interviewee.starred === true) {
          starredCount++;
        }
      });
    }
    return { applicantsCount, starredCount };
  };

  const fetchJobDetails = async (title) => {
    const db = getFirestore(app);
    const jobDocRef = doc(db, 'businesses', currentUser.uid, title, 'jobDescription');
    const jobDoc = await getDoc(jobDocRef);
    if (jobDoc.exists()) {
      return jobDoc.data();
    }
    return null;
  };

  useEffect(() => {
    // Fetch job details and applicants count
    const fetchJobData = async () => {
      const updatedPositions = await Promise.all(jobTitles.map(async (title) => {
        // Fetch createdAt from businesses collection
        const createdAt = await fetchCreatedAt(title);

        // Fetch applicants count and starred count from interviewees collection
        const { applicantsCount, starredCount } = await fetchApplicantsCount(title);

        // Fetch generatedLink
        const jobDetails = await fetchJobDetails(title);
        const generatedLink = jobDetails ? jobDetails.generatedLink : '';

        return { title, createdAt, applicants: applicantsCount, starred: starredCount, generatedLink };
      }));

      // Reverse the order of positions
      setPositions(updatedPositions.reverse());
    };

    fetchJobData();
  }, [jobTitles]);

  const handleEditPosition = async (index) => {
    const selectedJobTitle = positions[index].title;
    const jobDetails = await fetchJobDetails(selectedJobTitle);
    console.log("requirecamera", jobDetails.requireCamera);
    if (jobDetails) {
      setEditData({
        jobTitle: selectedJobTitle,
        jobDescription: jobDetails.jobDescription,
        customQuestions: jobDetails.customQuestions || [],
        aiQuestions: jobDetails.aiQuestions, // Populate AI questions based on the number stored
        personalityTraits: Object.entries(jobDetails.personalityTraits || {}).map(([skill, weighting]) => ({
          skill,
          weighting,
        })),
        timeLimit: jobDetails.timeLimit || '1:30',
        requireCamera: jobDetails.requireCamera !== undefined ? jobDetails.requireCamera : true,
        requireResume: jobDetails.requireResume !== undefined ? jobDetails.requireResume : true,
      });
      setIsPopupOpen(true);
    }
  };

  const handleRowClick = (index) => {
    const selectedJobTitle = positions[index].title;
    handleButtonClick('Candidates', selectedJobTitle); // Ensure the tab switches and the job is selected
  };

  const handleCopyLink = (link) => {
    navigator.clipboard.writeText(link).then(() => {
      alert('Interview link copied to clipboard');
    }).catch((error) => {
      console.error('Error copying link: ', error);
    });
  };

  return (
    <div className="positions-container">
      <div className="create-interview-container">
        <div className="create-interview">
          <h2>Create a new interview</h2>
          <p>Open a new position to start sending interviews</p>
          <button className="create-new-interview" onClick={handleCreatePosition}>Create new position</button>
        </div>
      </div>

      <div className="positions-list-container">
        <div className="positions-list">
          <h2>Your Positions</h2>
          
          {positions.length > 0 ? (
            positions.map((position, index) => (
              <div className="position-item" key={index} >
                <div className="position-details">
                  <p className="position-title" onClick={() => handleRowClick(index)} style={{ cursor: 'pointer' }} >{position.title} </p> 
                  <p className="position-meta">Created {position.createdAt} · {position.applicants} applicants · {position.starred} starred</p>
                </div>
                <div className="position-actions">
                  <button className="edit-button" onClick={() => handleCopyLink(position.generatedLink)}>Copy Interview Link</button>
                  <button className="edit-button" onClick={() => handleEditPosition(index)}>Edit</button>
                </div>
              </div>
              
              
            ))
          ) : (
            <p className="no-positions">No Active Positions Currently</p>
          )}
        </div>
      </div>

      <AddJobPopup
        isOpen={isPopupOpen}
        onClose={handlePopupClose}
        onSubmit={handleAddJob}
        editData={editData}
        existingJobTitles={jobTitles}
      />
    </div>
  );
};

export default PositionsSection;
