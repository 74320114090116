import React, { useEffect } from 'react';
import './welcomeBusinessInterview.css';
import './App.css';

const InterviewDone = () => {
  const navbar = document.querySelector('.menu-bar');
  if (navbar) {
    navbar.classList.add('hide');
  }

  useEffect(() => {
    // Add confetti class to body
    document.body.classList.add('confetti-active');

    // Remove the confetti effect after 3 seconds
    const timer = setTimeout(() => {
      document.body.classList.remove('confetti-active');
    }, 9000);

    return () => clearTimeout(timer); // Clean up
  }, []);

  return (
    <div className="interview-done-container">
      <div className="interview-content">
        <h1>Congrats, you’ve completed the interview!</h1>
        <p>Thank you for taking the time out of your day! A company representative will be reaching out to you with results soon.</p>
        <p>You can close out of this tab now. Thanks!</p>
      </div>
    </div>
  );
};

export default InterviewDone;