import { useState } from 'react';
import './HelpPopup.css';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { app } from './index';

const HelpPopup = () => {
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submitMessage, setSubmitMessage] = useState('');
  const db = getFirestore(app);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'helpMessages'), {
        name,
        company,
        email,
        helpmessage: message,
      });
      console.log('Document successfully written!');
      setName('');
      setCompany('');
      setEmail('');
      setMessage('');
      setSubmitMessage('Thanks! Someone will contact you for help soon.');
    } catch (error) {
      console.error('Error writing document: ', error);
    }
  };

  return (
    <div className="help-popup-container">
      <h1 className="help-title">We're Here to Help</h1>
      <form onSubmit={handleSubmit} className="help-form">
        <div className="input-row">
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name"
            className="help-input"
          />
          <input
            type="text"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            placeholder="Company"
            className="help-input"
          />
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="help-input"
          />
        </div>
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Write a message and a company representative will send you a message as soon as possible."
          className="help-textarea"
        />
        <button type="submit" className="help-submit-button">Submit</button>
        {submitMessage && <p className="submit-message">{submitMessage}</p>}
      </form>
    </div>
  );
};

export default HelpPopup;